import baxios from '../baxios.js'

const entitlementHelper = {
    // Bugbug? this is assuming there is only one entitlement per user per app
    upsertEntitlementOnUserByEmail: async (entitlementData) => {
        console.log(
            'upsertEntitlementOnUserByEmail: called with params',
            entitlementData
        )
        let { email, appName } = entitlementData
        if (!email || !appName) {
            throw new Error(
                `upsertEntitlementOnUserByEmail: email ${email} and appName ${appName} are required`
            )
        }

        // call /entitlements
        let entitlement = await baxios.post(`entitlements`, {
            email,
            appName,
            ...entitlementData,
        })

        if (entitlement.status !== 200) {
            throw new Error(
                `upsertEntitlementOnUserByEmail: error creating entitlement: ${entitlement.data}`
            )
        }

        return entitlement.data
    },
}

export default entitlementHelper
