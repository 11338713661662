import React, { useEffect, useState, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import productHelper from '../helpers/productHelper.js'
import stripeHelper from '../helpers/stripeHelper.js'
import Section from './Section.js'
import JSON5 from 'json5'
import CreatePriceModal from './CreatePriceModal.js'

const ProductForm = ({
    isEditorView,
    editingProduct,
    setEditingProduct,
    fetchProducts,
    editorRef,
    editorInstanceRef,
    handleEditorSave,
    collapsedSections = [],
    toggleSection,
    initializeEditor,
    updateEditorContent,
    activeStripePriceId,
    setActiveStripePriceId,
}) => {
    const [stripePriceOptions, setStripePriceOptions] = useState([])
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {
            stripePriceIdOptions: [], // Ensure this is set
        },
    })

    const [stripePriceInfo, setStripePriceInfo] = useState({})
    const [isCreatePriceModalOpen, setIsCreatePriceModalOpen] = useState(false)

    const handleStripeProductIdSave = async (stripeProductId) => {
        if (!stripeProductId) {
            toast.error('Stripe Product ID cannot be empty')
            return
        }

        try {
            await productHelper.updateProduct(editingProduct._id, {
                stripeProductId,
            })
            toast.success('Stripe Product ID saved successfully')
            fetchStripePriceInfo(stripeProductId)
        } catch (error) {
            toast.error(`Error saving Stripe Product ID: ${error.message}`)
        }
    }
    const handlePriceCreated = useCallback(
        async (newPrice) => {
            const updatedOptions = Array.isArray(stripePriceOptions)
                ? [...stripePriceOptions, newPrice.id]
                : [newPrice.id]
            setStripePriceOptions(updatedOptions)
            setValue('stripePriceIdOptions', updatedOptions)

            // Fetch updated price info for all options
            const priceInfo = {}
            for (const priceId of updatedOptions) {
                if (!priceId) continue
                const info = await stripeHelper.getStripePriceInfoFromPriceId(
                    priceId
                )
                console.log(
                    'ProductForm.js: handlePriceCreated - fetched price info:',
                    info
                )
                priceInfo[priceId] = info
            }
            console.log(
                'ProductForm.js: handlePriceCreated - setting stripePriceInfo with:',
                priceInfo
            )
            setStripePriceInfo(priceInfo)
        },
        [stripePriceOptions, setValue, setStripePriceOptions, stripeHelper]
    )

    const openCreatePriceModal = useCallback(() => {
        setIsCreatePriceModalOpen(true)
    }, [])

    const closeCreatePriceModal = useCallback(() => {
        setIsCreatePriceModalOpen(false)
    }, [])

    useEffect(() => {
        if (editingProduct) {
            setFormValues(editingProduct)
            setActiveStripePriceId(editingProduct.stripePriceId)
            console.log(
                'ProductForm.js: useEffect - editingProduct changed',
                editingProduct
            )
            fetchStripePriceInfo(editingProduct.stripeProductId)
        }
    }, [editingProduct])

    useEffect(() => {
        if (isEditorView && editorRef.current) {
            console.log(
                'ProductForm.js: useEffect - isEditorView changed, initializing editor'
            )
            initializeEditor(editingProduct)
        }
    }, [isEditorView])

    const fetchStripePriceInfo = useCallback(async (stripeProductId) => {
        if (stripeProductId) {
            console.log(
                'fetchStripePriceInfo: Fetching Stripe prices for product ID:',
                stripeProductId
            )
            try {
                const prices = await stripeHelper.getStripePricesForProduct(
                    stripeProductId
                )
                console.log(
                    'fetchStripePriceInfo: Fetched Stripe prices:',
                    prices
                )
                const priceInfo = {}
                const priceOptions = Array.isArray(prices)
                    ? prices.map((price) => price.id)
                    : []
                setStripePriceOptions(priceOptions)

                for (const priceId of priceOptions) {
                    const fullPriceInfo =
                        await stripeHelper.getStripePriceInfoFromPriceId(
                            priceId
                        )
                    priceInfo[priceId] = fullPriceInfo
                }
                console.log(
                    'fetchStripePriceInfo: Setting stripePriceInfo with:',
                    priceInfo
                )
                setStripePriceInfo(priceInfo)
            } catch (error) {
                console.error('Error fetching Stripe prices:', error)
                toast.error('Failed to fetch Stripe prices')
            }
        } else {
            setStripePriceOptions([])
            setStripePriceInfo({})
        }
    }, [])

    const setFormValues = (product) => {
        setValue('name', product.name)
        setValue('appName', product.appName)
        setValue('downloadUrlMacRelease', product.downloadUrlMacRelease)
        setValue('downloadUrlWinRelease', product.downloadUrlWinRelease)
        setValue('downloadUrlMacPrerelease', product.downloadUrlMacPrerelease)
        setValue('downloadUrlWinPrerelease', product.downloadUrlWinPrerelease)
        setValue(
            'downloadUrlSamplesPrerelease',
            product.downloadUrlSamplesPrerelease
        )
        setValue('activeStripePriceId', product.stripePriceId)
        setValue('stripeProductId', product.stripeProductId)
        setValue('needsExpiration', product.entitlementConfig?.needsExpiration)
        setValue(
            'validDurationHours',
            product.entitlementConfig?.validDurationHours
        )
        setValue('subType', product.entitlementConfig?.subType)
        setValue('relativeUrl', product.relativeUrl)
        setValue('details', product.details)
        console.log(
            'ProductForm.js: setFormValues - setting form values',
            product
        )
    }

    const onSubmit = async (data) => {
        if (isEditorView) {
            await handleEditorSave()
            setFormValues(
                JSON5.parse(editorInstanceRef.current.state.doc.toString())
            )
        } else {
            try {
                const {
                    newStripePriceIdOption,
                    stripeProductId,
                    ...cleanedData
                } = data
                const productData = {
                    ...cleanedData,
                    entitlementConfig: {
                        needsExpiration: cleanedData.needsExpiration,
                        validDurationHours: cleanedData.validDurationHours,
                        subType: cleanedData.subType,
                    },
                }

                // Only include stripeProductId if it's not empty
                if (stripeProductId) {
                    productData.stripeProductId = stripeProductId
                }

                if (editingProduct) {
                    await productHelper.updateProduct(
                        editingProduct._id,
                        productData,
                        {
                            headers: {
                                'X-Session-Id':
                                    localStorage.getItem('sessionId'),
                            },
                        }
                    )
                    toast.success('Product updated successfully')
                } else {
                    await productHelper.createProduct(productData, {
                        headers: {
                            'X-Session-Id': localStorage.getItem('sessionId'),
                        },
                    })
                    toast.success('Product created successfully')
                }
                fetchProducts()

                console.log(
                    'ProductForm.js: onSubmit - calling updateEditorContent with productData:',
                    productData
                )
                updateEditorContent(productData) // Update the editor with the new product data
                setEditingProduct(productData)
                setFormValues(productData) // Update the form values with the new product data
                fetchStripePriceInfo(productData.stripeProductId) // Fetch Stripe price info for the new product
            } catch (error) {
                toast.error(`Error submitting product: ${error.message}`)
            }
        }
    }

    const handleActivatePrice = async (priceId) => {
        if (window.confirm('Are you sure you want to activate this price?')) {
            setActiveStripePriceId(priceId)
            try {
                await productHelper.updateProduct(editingProduct._id, {
                    stripePriceId: priceId,
                })
                toast.success('Price activated and saved successfully')
            } catch (error) {
                toast.error(`Error saving price: ${error.message}`)
            }
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="product-form">
                <div
                    ref={editorRef}
                    className="editor-container"
                    style={{
                        // height: '400px',
                        border: '1px solid #ccc',
                        marginBottom: '10px',
                        display: isEditorView ? 'block' : 'none',
                    }}
                ></div>
                <div style={{ display: isEditorView ? 'none' : 'block' }}>
                    <input
                        {...register('name', { required: true })}
                        placeholder="Product Name"
                        className="product-input"
                    />
                    {errors.name && (
                        <p className="error-message">
                            Product name is required.
                        </p>
                    )}
                    <input
                        {...register('appName', { required: true })}
                        placeholder="App Name"
                        className="product-input"
                    />
                    {errors.appName && (
                        <p className="error-message">App name is required.</p>
                    )}
                    <Section
                        title="Release Build"
                        sectionKey="release"
                        collapsedSections={collapsedSections}
                        toggleSection={toggleSection}
                    >
                        <input
                            {...register('downloadUrlMacRelease')}
                            placeholder="Download URL Mac (Release)"
                            className="product-input"
                        />
                        <input
                            {...register('downloadUrlWinRelease')}
                            placeholder="Download URL Win (Release)"
                            className="product-input"
                        />
                    </Section>
                    <Section
                        title="Prerelease Build"
                        sectionKey="prerelease"
                        collapsedSections={collapsedSections}
                        toggleSection={toggleSection}
                    >
                        <input
                            {...register('downloadUrlMacPrerelease')}
                            placeholder="Download URL Mac (Prerelease)"
                            className="product-input"
                        />
                        <input
                            {...register('downloadUrlWinPrerelease')}
                            placeholder="Download URL Win (Prerelease)"
                            className="product-input"
                        />
                    </Section>
                    <Section
                        title="Prerelease Samples"
                        sectionKey="samples"
                        collapsedSections={collapsedSections}
                        toggleSection={toggleSection}
                    >
                        <input
                            {...register('downloadUrlSamplesPrerelease')}
                            placeholder="Download URL Samples (Prerelease)"
                            className="product-input"
                        />
                    </Section>
                    <Section
                        title="Stripe Product Information"
                        sectionKey="stripe"
                        collapsedSections={collapsedSections}
                        toggleSection={toggleSection}
                    >
                        <div className="product-info-card">
                            <h3>Product Details</h3>
                            <div className="form-group stripe-product-id">
                                <label htmlFor="stripeProductId">
                                    Stripe Product ID:
                                </label>
                                <div className="input-button-group">
                                    <input
                                        {...register('stripeProductId')}
                                        id="stripeProductId"
                                        placeholder="Stripe Product ID"
                                        className="product-input"
                                    />
                                    <button
                                        type="button"
                                        onClick={() =>
                                            handleStripeProductIdSave(
                                                getValues('stripeProductId')
                                            )
                                        }
                                        className="save-btn"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                            <style jsx="true">{`
                                .stripe-product-id {
                                    display: flex;
                                    flex-direction: column;
                                }
                                .stripe-product-id label {
                                    white-space: nowrap;
                                    margin-bottom: 5px;
                                }
                                .input-button-group {
                                    display: flex;
                                    align-items: center;
                                }
                                .input-button-group input {
                                    flex-grow: 1;
                                    margin-right: 10px;
                                }
                                .save-btn {
                                    padding: 8px 15px;
                                    background-color: #4caf50;
                                    color: white;
                                    border: none;
                                    border-radius: 4px;
                                    cursor: pointer;
                                    font-size: 14px;
                                    transition: background-color 0.3s;
                                }
                                .save-btn:hover {
                                    background-color: #45a049;
                                }
                            `}</style>
                            <p>
                                <strong>Product Name:</strong>{' '}
                                {editingProduct?.name || 'N/A'}
                            </p>
                            <p>
                                <strong>Active Price ID:</strong>{' '}
                                {activeStripePriceId ? (
                                    <a
                                        href={`https://dashboard.stripe.com/prices/${activeStripePriceId}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {activeStripePriceId}
                                    </a>
                                ) : (
                                    'N/A'
                                )}
                            </p>
                            <p>
                                <strong>Price:</strong>{' '}
                                {stripePriceInfo[activeStripePriceId]
                                    ?.unit_amount
                                    ? `$${(
                                          stripePriceInfo[activeStripePriceId]
                                              .unit_amount / 100
                                      ).toFixed(2)}`
                                    : 'Loading...'}
                            </p>
                            <div className="product-links">
                                {editingProduct?.stripeProductId && (
                                    <a
                                        href={`https://dashboard.stripe.com/products/${editingProduct.stripeProductId}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="stripe-link"
                                    >
                                        View Product in Stripe
                                    </a>
                                )}
                                {activeStripePriceId && (
                                    <a
                                        href={`https://dashboard.stripe.com/prices/${activeStripePriceId}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="stripe-link"
                                    >
                                        View Price in Stripe
                                    </a>
                                )}
                            </div>
                        </div>
                        <div className="product-info-card">
                            <h3>Stripe Price Options</h3>
                            <div className="stripe-price-options">
                                {stripePriceOptions
                                    .filter((option) => option)
                                    .map((option) => (
                                        <div
                                            key={option}
                                            className="stripe-price-option"
                                        >
                                            <div className="price-info">
                                                <span className="price-id">
                                                    {option}
                                                </span>
                                                <span className="price-amount">
                                                    {stripePriceInfo[option]
                                                        ?.unit_amount
                                                        ? `$${(
                                                              stripePriceInfo[
                                                                  option
                                                              ].unit_amount /
                                                              100
                                                          ).toFixed(2)}`
                                                        : 'Loading...'}
                                                </span>
                                            </div>
                                            <div className="price-actions">
                                                <a
                                                    href={`https://dashboard.stripe.com/prices/${option}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="view-link"
                                                >
                                                    View in Stripe
                                                </a>
                                                <button
                                                    type="button"
                                                    onClick={() =>
                                                        handleActivatePrice(
                                                            option
                                                        )
                                                    }
                                                    className="activate-btn"
                                                >
                                                    Activate
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                <style jsx="true">{`
                                    .stripe-price-options {
                                        display: flex;
                                        flex-direction: column;
                                        gap: 10px;
                                    }
                                    .stripe-price-option {
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                        padding: 10px;
                                        border: 1px solid #e0e0e0;
                                        border-radius: 8px;
                                        background-color: #f9f9f9;
                                        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                                    }
                                    .price-info {
                                        display: flex;
                                        flex-direction: column;
                                    }
                                    .price-id {
                                        font-weight: bold;
                                        color: #333;
                                    }
                                    .price-amount {
                                        color: #555;
                                    }
                                    .price-actions {
                                        display: flex;
                                        gap: 10px;
                                    }
                                    .view-link {
                                        color: #1a73e8;
                                        text-decoration: none;
                                    }
                                    .view-link:hover {
                                        text-decoration: underline;
                                    }
                                    .activate-btn {
                                        padding: 5px 10px;
                                        background-color: #4caf50;
                                        color: white;
                                        border: none;
                                        border-radius: 4px;
                                        cursor: pointer;
                                        transition: background-color 0.3s;
                                    }
                                    .activate-btn:hover {
                                        background-color: #45a049;
                                    }
                                `}</style>
                            </div>
                        </div>
                        <div>
                            <button
                                type="button"
                                onClick={openCreatePriceModal}
                                className="create-price-btn"
                            >
                                Create New Price
                            </button>
                        </div>
                    </Section>
                    <Section
                        title="Entitlement Configuration"
                        sectionKey="entitlementConfig"
                        collapsedSections={collapsedSections}
                        toggleSection={toggleSection}
                    >
                        <input
                            type="checkbox"
                            {...register('needsExpiration')}
                            className="product-input"
                        />
                        <input
                            {...register('validDurationHours')}
                            placeholder="Valid Duration Hours"
                            className="product-input"
                        />
                        <input
                            {...register('subType')}
                            placeholder='e.g. "trial"'
                            className="product-input"
                        />
                    </Section>
                </div>
                <button type="submit" className="submit-btn">
                    {editingProduct ? 'Update' : 'Submit'}
                </button>
                {editingProduct && (
                    <button
                        type="button"
                        onClick={() => setEditingProduct(null)}
                        className="cancel-btn"
                    >
                        Cancel Edit
                    </button>
                )}
                <style jsx="true">{`
                    .product-info-card {
                        border: 1px solid #e0e0e0;
                        border-radius: 8px;
                        padding: 20px;
                        margin-bottom: 20px;
                        background-color: #f9f9f9;
                        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                    }
                    .product-info-card h3 {
                        margin-top: 0;
                        margin-bottom: 15px;
                        color: #333;
                        font-size: 1.2em;
                    }
                    .product-info-card p {
                        margin: 10px 0;
                        font-size: 1em;
                        color: #555;
                    }
                    .product-info-card strong {
                        color: #333;
                    }
                    .product-links {
                        margin-top: 15px;
                    }
                    .stripe-link {
                        display: inline-block;
                        margin-right: 10px;
                        padding: 8px 12px;
                        background-color: #6772e5;
                        color: white;
                        text-decoration: none;
                        border-radius: 4px;
                        font-size: 0.9em;
                        transition: background-color 0.3s ease;
                    }
                    .stripe-link:hover {
                        background-color: #5469d4;
                    }
                    .create-price-btn {
                        background-color: #28a745;
                        color: white;
                        border: none;
                        padding: 10px 15px;
                        border-radius: 4px;
                        cursor: pointer;
                        margin-top: 10px;
                        font-size: 1em;
                        transition: background-color 0.3s ease;
                    }
                    .create-price-btn:hover {
                        background-color: #218838;
                    }
                    .activate-btn {
                        margin-left: 10px;
                        padding: 5px 10px;
                        background-color: #007bff;
                        color: white;
                        border: none;
                        border-radius: 4px;
                        cursor: pointer;
                        font-size: 0.9em;
                        transition: background-color 0.3s ease;
                    }
                    .activate-btn:hover {
                        background-color: #0056b3;
                    }
                `}</style>
            </form>
            <CreatePriceModal
                isOpen={isCreatePriceModalOpen}
                onClose={closeCreatePriceModal}
                onPriceCreated={handlePriceCreated}
                productId={getValues('stripeProductId')}
            />
        </>
    )
}
export default ProductForm
