import baxios from '../baxios.js'

const configHelper = {
    getAllConfigs: () => {
        return baxios.get(`/configs`)
    },
    getConfigByName: (name) => {
        return baxios.get(`/configs/${name}`)
    },
    updateConfig: (id, params) => {
        return baxios.post(`/configs/${id}`, { params })
    },
    createNewConfig: (name, params) => {
        return baxios.post(`/configs`, { name, params })
    },
}

export default configHelper
